.container {
  padding: 4rem;
}

#photos {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}

@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
  .container {
    padding: 2rem;
  }
}
@media (max-width: 400px) {
  #photos {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
  .container {
    padding: 1rem;
  }
}
#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
  margin-bottom: 1rem;
}

.form-control {
  display: grid;
  place-items: center;
  padding-bottom: 5vh;
}
.navbar h1 {
  padding-left: 3rem;
}
.navbar {
  width: 100%;
  height: 10vh;
  background-color: #2a9d8f;
  color: white;
  display: flex;
  align-items: center;
  padding: 3rem;
}
.myFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2a9d8f;
  margin-top: 5vh;
  height: 10vh;
}
.loader-container {
  display: flex;
  justify-content: center;
}
.image-loader {
  width: 50vw;
}
.select-class {
  padding: 8px;
  background-color: #2a9d8f;
  outline: #8f5e56;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
}
.option-class {
  border: none;
  outline: none;
  padding: 8px;
}
